import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EncryptStorage } from 'encrypt-storage';
import { environment } from '../../environments/environment';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  envChange = new EventEmitter();
  env: string;
  url: string;
  adminUrl!: string;
  organisation: any;
  encryptStorage = new EncryptStorage(environment.jwtTokenEncryptionKey, {
    prefix: '@sudo',
    storageType: 'localStorage',
  });

  constructor(public http: HttpClient) {
    this.env = this.getEnvironment();
    this.url = environment.apiUrl;
    this.adminUrl = environment.adminUrl

    this.envChange.subscribe(data => this.organisation = data);
  }

  getUrl() {
    this.env = this.getEnvironment();
    this.url = environment.apiUrl

    return this.url;
  }

  getEnvironment() {
    if (!this.encryptStorage.getItem('env')) {
      this.setEnvironment('SANDBOX');
    }
    else if (!['LIVE', 'SANDBOX'].includes(this.encryptStorage.getItem('env')!)) {
      this.setEnvironment('SANDBOX');
    }
    return this.encryptStorage.getItem('env')!;
  }

  setEnvironment(env: string) {
    this.encryptStorage.setItem('env', env);
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.getUrl() + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.getUrl() + endpoint, body, reqOpts);
  }

  // postSandbox(endpoint: string, body: any, reqOpts?: any) {
  //   return this.http.post(environment.sandboxAPIUrl + endpoint, body, reqOpts);
  // }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.getUrl() + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.getUrl() + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.getUrl() + endpoint, body, reqOpts);
  }

  // Special external api endpoints

  // API endpoint to fetch all countries
  getAllCountries() {
    return this.http.get('assets/json/countries.json');
  }
  // API endpoint to fetch MCC
  getMCC() {
    return this.http.get(`/assets/json/mcc.json`);
  }
  // Endpoint to upload file
  uploadFile(url: string, file: any, reqOpts?: any) {
    return this.http.put(url, file, reqOpts);
  }

  uploadDocument(file: any, reqOpts?: any) {
    return this.http.post(this.getUrl() + `entities/generate-upload-url`, file, reqOpts);
  }

  verifyDirectorPhone(body: any) {
    return this.http.put(`${this.getUrl()}entities/director/verify-phone`, body)
  }

}
